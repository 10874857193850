import { useQuery, useMutation, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { RuleConditions } from "./RuleConditions"
import { EditMetaData } from "./EditMetaData";
import { EditAttributes } from "./EditAttributes";
import "./EditRule.css"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { MenuItem, Alert, Accordion, AccordionSummary, AccordionDetails, Box } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import Fade from "@mui/material/Fade";
import { ExpandMore } from "@mui/icons-material";


const getRulesGQL = gql`
  query EditRulesGET($ruleBaseId: String!, $ruleId: Int!) {
    customer {
      getRules(filter: {ruleBaseId: $ruleBaseId, ruleId: $ruleId}) {
        items {
            ruleId
            ruleName
            event
            conditions
            isDeletedOn
            rulePriority
            status
            attributes
            parentRuleIDs
            pRuleConditions
        }
       }
        getRuleBase(filter: {ruleBaseId: $ruleBaseId}){
            ruleBaseId
            ruleBaseName
            schema
            metaDataSchema
            attributes
      }
    }
  }
`;


const updateRuleGQL = gql`
    mutation EditRulesEDIT($ruleBaseId: String!, $ruleId: Int!, $ruleName: String, $event: String, $conditions: String, $rulePriority: Int, $status: String, $attributes: String, $parentRuleIDs: String) {
    updateRule(where: {ruleBaseId: $ruleBaseId, ruleId: $ruleId, ruleName: $ruleName, event: $event, conditions: $conditions, rulePriority: $rulePriority, status: $status, attributes: $attributes, parentRuleIDs: $parentRuleIDs}) {
        ruleName
        event
        conditions
        isDeletedOn
        rulePriority
        status
        attributes
        parentRuleIDs
        pRuleConditions
    }
  }
`;

export const EditRule = () => {
    const { ruleBaseId, ruleId } = useParams()
    const navigate = useNavigate()
    const [currentRule, setCurrentRule] = useState()
    const [alert, setAlert] = useState(false)

    const { loading, error, data } = useQuery(getRulesGQL, {
        variables: { ruleBaseId, ruleId },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });
    const [updateRule] = useMutation(updateRuleGQL, {
      refetchQueries: ["EditRulesGET"],
    });

    const countArrItems = (arr) => {
      let parsedArr = arr ?? []
      if (typeof arr === 'string') {
        parsedArr = JSON.parse(parsedArr)
      }
      const count = parsedArr?.length ?? 0
      return count
    }
    
    const parentConditionCount = countArrItems(currentRule?.pRuleConditions ?? [])
    
    function getOperators() {

        let statusList = [
            { v: "active", o: "Active" },
            { v: "inactive", o: "Inactive" },
        ]
        return statusList;
    }

    const handleControlledInputChange = (rule) => {
        const newRule = Object.assign({}, currentRule)
        newRule[rule.target.name] = rule.target.value
        setCurrentRule(newRule)
    }

    const SaveRule = () => {
        updateRule({ variables: { ruleBaseId, ...currentRule } })
        setAlert((prev) => !prev)
        // console.log("alert", alert)
    }

    useEffect(() => {
     if (data && !loading) {
        const ruleFromApi = data.customer.getRules.items[0];
        setCurrentRule(ruleFromApi)
     }   
    }, [data, loading])
    
    if (!currentRule) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
        
    return (
      <div key={ruleBaseId}>
        <form className="RuleForm">
          <div className="Form__title">EDIT RULE</div>
          <div className="RuleNumber">
            Rule Number: {ruleId + " "}
            <Button
              variant="outlined"
              type="Save"
              onClick={(evt) => {
                evt.preventDefault();
                SaveRule();
              }}
              disabled={loading}
              className="btn btn-primary"
            >
              Save
            </Button>
            <span className="BackButton">
              <Button
                variant="outlined"
                onClick={() => {
                  navigate(-1);
                }}
                disabled={loading}
              >
                Back
              </Button>
            </span>
          </div>
          <Fade
            in={alert}
            timeout={{ enter: 1000, exit: 6000 }}
            addEndListener={() => {
              setTimeout(() => {
                setAlert(false);
              }, 2000);
            }}
          >
            <Alert
              sx={{
                zIndex: "1000",
                marginBottom: "1rem",
                position: "fixed",
                bottom: "0",
                right: "0",
              }}
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
            >
              Save Successful
            </Alert>
          </Fade>
          <div className="Box5">
            <span className="Box6">
              <TextField
                label="Rule Name"
                type="Name"
                name="ruleName"
                autoFocus
                className="form-control"
                value={currentRule?.ruleName}
                onChange={handleControlledInputChange}
                size="small"
              />
            </span>
            <TextField
              label="Status"
              name="status"
              autoFocus
              className="form-control"
              select
              value={currentRule?.status}
              onChange={handleControlledInputChange}
              sx={{ width: "15ch", marginRight: "5px" }}
              size="small"
            >
              {getOperators(currentRule?.status).map((varItem, index) => (
                <MenuItem key={index} value={varItem.v}>
                  {varItem.o}
                </MenuItem>
              ))}
            </TextField>
            {currentRule?.rulePriority === 0 ? (
              <TextField
                label="Priority Level"
                type="Name"
                name="rulePriority"
                autoFocus
                className="form-control"
                value=""
                onChange={handleControlledInputChange}
                sx={{ width: "15ch", marginRight: "5px" }}
                size="small"
              />
            ) : currentRule?.rulePriority > 1000 ||
              currentRule?.rulePriority < 0 ? (
              <TextField
                label="Priority Level"
                type="Name"
                name="rulePriority"
                autoFocus
                className="form-control"
                value={0}
                onChange={handleControlledInputChange}
                sx={{ width: "15ch", marginRight: "5px" }}
                size="small"
              />
            ) : (
              <TextField
                label="Priority Level"
                type="Name"
                name="rulePriority"
                autoFocus
                className="form-control"
                value={currentRule?.rulePriority}
                onChange={handleControlledInputChange}
                sx={{ width: "15ch", marginRight: "5px" }}
                size="small"
              />
            )}
            <TextField
              label="Parent Rule IDs"
              type="Name"
              name="parentRuleIDs"
              autoFocus
              className="form-control"
              value={currentRule?.parentRuleIDs}
              onChange={handleControlledInputChange}
              sx={{ marginRight: "5px" }}
              size="small"
            />
          </div>

          <div className="form-group">
            <EditAttributes
              attributes={data.customer.getRuleBase.attributes}
              setRule={setCurrentRule}
              rule={currentRule}
            />
          </div>

          {parentConditionCount > 0 && (
            <Box
              style={{
                marginBottom: 1,
                border: "2px groove threedface",
                borderRadius: 0,
                marginInline: "2px",
              }}
            >
              <Accordion
                elevation={0}
                sx={{
                  background: "transparent",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{ paddingLeft: ".75em" }}
                >
                  Parent Rules ({parentConditionCount})
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <RuleConditions
                      rule={currentRule}
                      setRule={setCurrentRule}
                      schema={data.customer.getRuleBase.schema}
                      disabled
                      variant="parent-conditions"
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </Box>
          )}

          <fieldset>
            <label className="WhenTitle">When </label>
            <div className="form-group">
              <RuleConditions
                rule={currentRule}
                setRule={setCurrentRule}
                schema={data.customer.getRuleBase.schema}
                disabled={loading}
              />
            </div>
          </fieldset>
          <fieldset>
            <div className="form-group">
              <label htmlFor="Name">Then</label>
              <EditMetaData
                metaData={data.customer.getRuleBase.metaDataSchema}
                setRule={setCurrentRule}
                rule={currentRule}
                rulePriority={currentRule?.rulePriority}
                disabled={loading}
              />
            </div>
          </fieldset>
        </form>
      </div>
    );
};