import React from "react"
import "./EditMetaData.css"
import { MenuItem, TextField, Checkbox, FormControlLabel, Grid } from '@mui/material'

export const EditMetaData = (props) => {
    const rule = props.rule;
    const metaDataSchema = props.metaData ? JSON.parse(props.metaData) : {}
    const eventData = props.rule.event ? JSON.parse(props.rule.event) : {}
    const rulePriority = props.rulePriority
    const disableFields = !!props.disabled

    function buildEvent() {
        let eventList = []

        for (var schemaItem in metaDataSchema.properties) {
            let itemVal = ""
            let usePriorityVal = ""
            let itemEnum = []

            if (metaDataSchema.properties[schemaItem].enum) {
                itemEnum = metaDataSchema.properties[schemaItem].enum
            }

            for (var eventIndex in eventData) {
                if (eventData[eventIndex].name === schemaItem) {
                    itemVal = eventData[eventIndex].val
                    usePriorityVal = eventData[eventIndex].usePriority
                }
            }
            let item = { name: schemaItem, val: itemVal, usePriority: usePriorityVal, type: metaDataSchema.properties[schemaItem].type, enum: itemEnum }
            eventList.push(item)
        }
        return eventList;
    }

    const updateEventValByName = (value, name) => {
        let newEvent = []
        let currentEvent = buildEvent()

        for (var eventIndex in currentEvent) {
            let eventItem = currentEvent[eventIndex]

            if (eventItem.name === name) {
                eventItem.val = value

            }
            if (!eventItem.usePriority) {
                eventItem.usePriority = 0
            }
            newEvent.push(eventItem)
        }
        const updateRuleJson = { ...rule, event: JSON.stringify(newEvent) }
        props.setRule({ ...updateRuleJson })
    }

    const updateEventCheckByName = (usePriority, name) => {
        let newEvent = []
        let currentEvent = buildEvent()

        for (var eventIndex in currentEvent) {
            let eventItem = currentEvent[eventIndex]

            if (eventItem.name === name) {
                eventItem.usePriority = usePriority
            }
            newEvent.push(eventItem)
        }
        const updateRuleJson = { ...rule, event: JSON.stringify(newEvent) }
        props.setRule({ ...updateRuleJson })
    }

    return (
        <>
            {buildEvent().map((item, index) =>
                <Grid container spacing={2} key={index} sx={{ margin: '.5rem 0' }}>
                    <Grid item
                        xs={item.type === "string" ? 4 : 2}
                    >
                        {item.enum && item.enum.length > 0 ? (
                            <TextField
                                select
                                value={item.val}
                                className="MetaDataName"
                                label={item.name} type="text" name="val" defaultValue={item.val}
                                size="small"
                                onChange={(e) => updateEventValByName(e.target.value, item.name)}
                                disabled={disableFields}
                            >
                                <MenuItem value=""></MenuItem>
                                {item.enum.map((single_condition_operator, index) =>
                                    <MenuItem key={index} value={single_condition_operator}>{single_condition_operator}</MenuItem>
                                )}
                            </TextField>
                        ) : <TextField
                            className="MetaDataName"
                            label={item.name} type="text" name="val" defaultValue={item.val}
                            size="small"
                            onChange={(e) => updateEventValByName(e.target.value, item.name)}
                            disabled={disableFields}
                            />
                        }

                    </Grid>
                    {rulePriority > 0 && rulePriority <= 1000 ?
                        <Grid item>
                            <FormControlLabel
                                value="top"
                                control={<Checkbox
                                    checked={item.usePriority && item.usePriority == 1 ? true : false}
                                    onChange={(e) => updateEventCheckByName(item.usePriority && item.usePriority == 1 ? 0 : 1, item.name)}
                                />}
                                label="Priority"
                                labelPlacement="end"
                                disabled={disableFields}
                            />
                        </Grid>
                        : ""}
                </Grid>
            )
            }
        </>
    )
}